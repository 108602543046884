<template>
    <div>
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="personinfo">
            <div class="top">
                <div class="userinfo">
                    <img :src="userinfo.postAuthor.avatar_url"/>
                    <p style="color:#1989fa;;font-size:0.5rem">{{userinfo.postAuthor.author}}</p>
                    <p class="fans"><span>关注：{{ authormessage.followTotals }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span>粉丝：{{ authormessage.fansTotals }}</span></p>
                    <div class="button" v-if="userid!==userinfo.userId">
                        <van-button @click="follow(1)" v-if="!authormessage.isMyFollow" icon="plus" type="danger">关注</van-button>
                        <van-button @click="follow(0)" v-else icon="success" type="primary">已关注</van-button>
                    </div>
                </div>
                <div class="toinline">
                    <p>发帖数量</p>
                    <p>{{userinfo.postNum}}</p>
                </div>
                <div class="toinline">
                    <p>成绩</p>
                    <p>{{userinfo.postNum}}中{{userinfo.hitPost}}</p>
                </div>
                <div class="toinline">
                    <p>回报率</p>
                    <p v-if="(userinfo.weekRoi!==undefined)">{{userinfo.weekRoi}}%</p>
                    <p v-if="(userinfo.monthRoi!==undefined)">{{userinfo.monthRoi}}%</p>
                </div>
            </div>
        
            <div class="centerinfo">
                <van-index-bar :index-list="indexList" highlight-color="red">
                    <div class="list" v-for="(itemone, index) in postList" :key="index">
                        <van-index-anchor index="index"><span>{{itemone.periodText}}</span><span style="text-align:center;color:red;margin-left: 2.5rem;">{{itemone.lotteryContent}}</span></van-index-anchor>
                        <hr style="width: 90%;opacity: 0.1;" />
                            <div class="head_bar">
                                <van-image class="photo" round fit="cover" :src="userinfo.postAuthor.avatar_url" />
                                <span class="name">{{userinfo.postAuthor.author}} <span v-if="itemone.wonLotterySts===1" style="color:#fff;background-color:red;font-size:0.4rem;">中</span></span>
                                <div class="icon_box">
                                <div class="icon">
                                    <van-icon name="warn-o" size="20" color="#999" />
                                </div>
                                <div class="icon collect" @click="goCollect(itemone)">
                                    <van-icon name="star-o" size="20" color="#999" />
                                </div>
                                </div>
                            </div>
                            <p>第{{itemone.periodText}}<span class="postTime">{{itemone.postTime | setDateMMdd}}</span><span class="postType" v-if="lotteryKind==='5'">排列五</span><span class="postType" v-else >七星彩</span><span v-if="itemone.roi>0" class="rateReturn">回报率:{{itemone.roi}}%</span></p>
                            <div class="contents van-hairline--bottom">
                                <!-- 方案 -->
                                <div >
                                <div v-for="(ite,inde) in itemone.forumDetailsModels" :key="inde">
                                    <!-- <div v-html="ite.contents"></div> -->
                                    <p :class="ite.wonLotterySts===1?'red':'black'">[{{ite.categoryName}}]</p>
                                    <div v-if="ite.categoryName==='杀头'||ite.categoryName==='杀百'||ite.categoryName==='杀十'||ite.categoryName==='杀尾'">
                                    <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                                        千位：
                                        <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                                        百位：
                                        <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                                        十位：
                                        <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                                        个位：
                                        <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='死数'">
                                    <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='稳码'">
                                    <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='头尾合'">
                                    <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='中肚合'">
                                    <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPointGeneralMajor!==''&&ite.textIndexPointGeneralMajor!==null&&ite.textIndexPointGeneralMajor!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralFocus!==''&&ite.textIndexPointGeneralFocus!==null&&ite.textIndexPointGeneralFocus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='二字现'">
                                    <p v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_twoshow.includes(item)?'red':'black'">{{item}} </span> 
                                    </p>
                                    </div>
                                    <div v-else-if="ite.categoryName==='三字现'">
                                    <p style="word-break:break-word;" v-if="ite.textIndexPointGeneral!==''&&ite.textIndexPointGeneral!==null&&ite.textIndexPointGeneral!==undefined">
                                        
                                        <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_threeshow.includes(item)?'red':'black'">{{item}} </span> 
                                        
                                    </p>
                                    </div>
                                    <div v-else>
                                    <p v-if="ite.textIndexPoint1!==''&&ite.textIndexPoint1!==null&&ite.textIndexPoint1!==undefined">
                                        千位：
                                        <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint1Major!==''&&ite.textIndexPoint1Major!==null&&ite.textIndexPoint1Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint1Focus!==''&&ite.textIndexPoint1Focus!==null&&ite.textIndexPoint1Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint2!==''&&ite.textIndexPoint2!==null&&ite.textIndexPoint2!==undefined">
                                        百位：
                                        <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint2Major!==''&&ite.textIndexPoint2Major!==null&&ite.textIndexPoint2Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint2Focus!==''&&ite.textIndexPoint2Focus!==null&&ite.textIndexPoint2Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint3!==''&&ite.textIndexPoint3!==null&&ite.textIndexPoint3!==undefined">
                                        十位：
                                        <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint3Major!==''&&ite.textIndexPoint3Major!==null&&ite.textIndexPoint3Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint3Focus!==''&&ite.textIndexPoint3Focus!==null&&ite.textIndexPoint3Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    <p v-if="ite.textIndexPoint4!==''&&ite.textIndexPoint4!==null&&ite.textIndexPoint4!==undefined">
                                        个位：
                                        <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                        <span v-if="ite.textIndexPoint4Major!==''&&ite.textIndexPoint4Major!==null&&ite.textIndexPoint4Major!==undefined">
                                            主攻
                                            <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint4Focus!==''&&ite.textIndexPoint4Focus!==null&&ite.textIndexPoint4Focus!==undefined">
                                            重点
                                            <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                            </span>
                                        </span>
                                    </p>
                                    </div>
                                    
                                    
                                
                                
                                </div>
                                </div>
                            
                                <!-- 照片 -->
                                <!-- <div class="photo_box">
                                <van-image class="photo" fit="cover" v-for="(row, i) in itemone.imagesUrlArray" :key="i + 1000000" :src="row" @click="goImagePreview(item.imagesUrlArray, i )" />
                                </div> -->
                            </div>
                            <div class="foot_bar">
                                <div :class="itemone.upCount == '1' ? 'icon Selected' : 'icon'" @click="goThumbsSts(itemone, index)">
                                <van-icon name="good-job-o" size="24" color="#999" />
                                {{itemone.upCount || 0}}
                                </div>
                                <div class="icon" @click="goShare()" >
                                <van-icon name="share-o" size="24" color="#999" />
                                {{itemone.shareCount || 0}}
                                </div>
                                <div class="icon">
                                <van-icon name="comment-o" size="24" color="#999" />
                                {{itemone.commentCount || 0}}
                                </div>
                            </div>
                        
                    </div>
                

                </van-index-bar>
                <!-- <v-content :list="postList"></v-content> -->
            </div>
        </div>
        
   
    </div>
</template>

<script>
import Moment from 'moment'
import vHeader from "@/components/header.vue";
import wxShare from "@/assets/js/util/wxShare.js";
import vContent from '@/components/content.vue';
export default {
    data(){
        return{
            userid:this.$store.state.common.loginInfo.userId,//登录用户的id
            authormessage:'',
            type:this.$route.query.type,
            title:this.$route.query.title,
            period:this.$route.query.period,
            lotteryKind:this.$store.state.common.lotteryKind,
            userinfo:JSON.parse(this.$route.query.userinfo),
            indexList:[],
            postList:[],
            access_token: this.$store.state.common.loginInfo.access_token,
        }
    },
    components:{
        vHeader,
        vContent,
    },
    filters: {
        setDateMMdd(vle) {
        if (vle) { return Moment(vle).format('MM月DD') }
        return '';
        }
    },
    methods:{
        // 收藏帖子（1）收藏（0）取消收藏
        async goCollect(item) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_FAV_STATUS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.$toast('收藏成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        // 点赞（1）点踩（2） 或 取消（3）
        async goThumbsSts(item, index) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_THUMBS_STS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.postList[index].upCount = '1'
            this.$toast('点赞成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        goShare(){
        wxShare.goShare({
            shareTitle: '测试标题',
            shareText: '测试内容',
            shareImgUrl: '/logo.jpg',
            shareUrl: document.URL
        });
        },
        async getpersonmonth(){
            let data={
                month_period:this.$route.query.period,
                post_author_user_id:this.userinfo.userId,
                lottery_kind:this.lotteryKind

            }
            const res = await this.$HTTP.get(this, this.$API['API_MONTHLY_AUTHOR_LIST'], data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            if (res.statusCode === 200) {
               
                // console.log('个人月的详细信息',res)

                let arr=res.data.map(item=>{
                    item.roi=parseFloat(item.roi).toFixed(2)
                    item.postAuthor=JSON.parse(item.postAuthor)
                    const qian=item.lotteryContent!==null?item.lotteryContent.substr(0,1):'', bai=item.lotteryContent!==null?item.lotteryContent.substr(1,1):'',shi=item.lotteryContent!==null?item.lotteryContent.substr(2,1):'',ge=item.lotteryContent!==null?item.lotteryContent.substr(3,1):'',winning_number_twoshow=[],winning_number_threeshow=[]
                    let qiangesum='',baishisum='',winning_number=''

                    if(item.lotteryContent!==null){
                    baishisum=(parseInt(bai)+parseInt(shi))%10+''
                    qiangesum=(parseInt(qian)+parseInt(ge))%10+''
                    winning_number=qian+bai+shi+ge
                    let first
                    let second
                    let three
                    let temp
                    let temp2
                    for(let i=0;i<winning_number.length;i++){
                        first=winning_number.substr(i,1)
                        temp=winning_number.replace(winning_number[i],'')
                        for(let j=0;j<temp.length;j++){
                        second=temp[j]
                        winning_number_twoshow.push(first+second)
                        temp2=temp.replace(temp[j],'')
                        for(let k=0;k<temp2.length;k++){
                            three=temp2[k]
                            winning_number_threeshow.push(first+second+three)
                        }
                        }
                    }
                    
                    }else{
                    baishisum=''
                    qiangesum=''
                    winning_number=''
                    }
                    
                    let itemData={
                    ...item,
                    qian,
                    bai,
                    shi,
                    ge,
                    qiangesum,
                    baishisum,
                    winning_number,
                    winning_number_twoshow,
                    winning_number_threeshow
                    }
                    return itemData
                })
                this.postList=arr

                // console.log('test修改返回的数据',this.postList)
                
            }
        },
        async getpersonweek(){
            let data={
                week_period:this.$route.query.period,
                post_author_user_id:this.userinfo.userId,
                lottery_kind:this.lotteryKind

            }
            const res = await this.$HTTP.get(this, this.$API['API_WEEKLY_AUTHOR_LIST'], data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
            if (res.statusCode === 200) {
               
                // console.log('个人周的详细信息',res)

                let arr=res.data.map(item=>{
                    item.roi=parseFloat(item.roi).toFixed(2)
                    item.postAuthor=JSON.parse(item.postAuthor)
                    const qian=item.lotteryContent!==null?item.lotteryContent.substr(0,1):'', bai=item.lotteryContent!==null?item.lotteryContent.substr(1,1):'',shi=item.lotteryContent!==null?item.lotteryContent.substr(2,1):'',ge=item.lotteryContent!==null?item.lotteryContent.substr(3,1):'',winning_number_twoshow=[],winning_number_threeshow=[]
                    let qiangesum='',baishisum='',winning_number=''

                    if(item.lotteryContent!==null){
                    baishisum=(parseInt(bai)+parseInt(shi))%10+''
                    qiangesum=(parseInt(qian)+parseInt(ge))%10+''
                    winning_number=qian+bai+shi+ge
                    let first
                    let second
                    let three
                    let temp
                    let temp2
                    for(let i=0;i<winning_number.length;i++){
                        first=winning_number.substr(i,1)
                        temp=winning_number.replace(winning_number[i],'')
                        for(let j=0;j<temp.length;j++){
                        second=temp[j]
                        winning_number_twoshow.push(first+second)
                        temp2=temp.replace(temp[j],'')
                        for(let k=0;k<temp2.length;k++){
                            three=temp2[k]
                            winning_number_threeshow.push(first+second+three)
                        }
                        }
                    }
                    
                    }else{
                    baishisum=''
                    qiangesum=''
                    winning_number=''
                    }
                    
                    let itemData={
                    ...item,
                    qian,
                    bai,
                    shi,
                    ge,
                    qiangesum,
                    baishisum,
                    winning_number,
                    winning_number_twoshow,
                    winning_number_threeshow
                    }
                    return itemData
                })
                this.postList=arr.reverse()

                // console.log('test修改返回的数据',this.postList)
                
            }
        },
        async getauthorinfo(){
            let data={
                master_user_id:this.userinfo.userId,
            }
            const res = await this.$HTTP.get(this, this.$API['API_ONE_FORUM_ANON_FOLLOW_SITUATION'], data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                if (res.statusCode === 200) {
                    // console.log('作者信息',res.data)
                    this.authormessage=res.data
                    // this.$set(this.authormessage,'followTotals',res.data.followTotals)
                    // this.$set(this.authormessage,'fansTotals',res.data.fansTotals)
                    // this.$set(this.authormessage,'isMyFollow',res.data.isMyFollow)
                    // this.$forceUpdate();
                    // this.followTotals=res.data.followTotals
                    // this.fansTotals=res.data.fansTotals
                    // this.isMyFollow=res.data.isMyFollow

                }
        },
        async follow(n){
            let data={
                "kindId": 1,
                "status": n, //1为关注 0为取消关注
                "systemId": 100001,
                "targetUserId": this.userinfo.userId
            }
            const res = await this.$HTTP.put(this, 'API_ONE_FORUM_FANS_FOLLOW', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
                if (res.statusCode === 200) {
                    // console.log('关注信息',res.data)
                    this.getauthorinfo()
                    // window.location.reload()

                }
        },
    },
    mounted(){
        // console.log(this.userinfo)
        // console.log(this.type)
        if(this.type==='1'){
        sessionStorage.setItem('period',this.period);
        this.getpersonweek()

        }
        if(this.type==='2'){
        sessionStorage.setItem('periodMonth',this.period);
        this.getpersonmonth()

        }
         this.getauthorinfo();
      
    }
}
</script>

<style lang="less" scoped>
.personinfo{
    position: fixed;
    top: 1.2rem;
    left: 0rem;
    bottom: 0rem;
    right: 0rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;
    .top{
    
        img{
                width: 2rem;
                height: 2rem;
                margin: 0.3rem;
                border-radius: 50%;
        }
        p{
            font-size: 0.38rem;
            margin: 0;
        }
        .userinfo{
            text-align: center;
            width: 100%;
            height: 4rem;
            .button{
                position: absolute;
                right: 0.5rem;
                top: 2rem;
            }

        }

    }
    .centerinfo{
        
        .list {
        background-color: white;
        margin: 4px;
        padding: 10px 10px 0;
        p {
            margin: 0;
            font-size: 15px;
        }
        .head_bar {
            display: flex;
            align-items: center;
            .photo {
            width: 40px;
            height: 40px;
            }
            .name {
            font-size: 18px;
            margin-left: 10px;
            flex: 1;
            }
            .icon_box {
            display: flex;
            .icon {
                background-color: #e5e5e5;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-left: 12px;
            }
            .icon:active {
                color: white;
                background-color: #fb3e44;
            }
            }
        }
        .postTime {
            color: #999;
            margin-left: 10px;
        }
        .postType {
            color: red;
            margin-left: 10px;
        }
        .rateReturn{
            // margin-left: 3.8rem;
            font-size: 0.3rem;
            margin-right: 0.3rem;
            float: right;
        }
        .contents {
            padding-bottom: 10px;
            .photo_box {
            margin-top: 12px;
            .photo {
                width: 72px;
                height: 72px;
                margin: 0 8px 2px 0;
            }
            }
        }
        .foot_bar {
            display: flex;
            justify-content: space-around;
            .icon {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 15px;
            color: #999;
            }
            .Selected {
            color: #3abaf7;
            .van-icon {
                color: #3abaf7 !important;
            }
            }
        }
        }
    }
}

.red{
    color: red;
}
.black{
    color:black;
}
.toinline{
    display: inline-block;
    width: 33%;
    text-align: center;
}

</style>